.footer {
  position: fixed;
  bottom: 0%;
  min-height: 4vh;
  vertical-align: middle;
  text-align: center;
  color: #FFFFFF;
}

.footercontact{
  display: none;
}

.footer a {
  text-decoration: none;  
  color: #FFFFFF;
}

/* Adapt the website for responsive devices */
@media screen and (max-width: 768px) {
  .footer {
    position: sticky;
    bottom: 0px;
    min-height: 4vh;
    vertical-align: middle;
    text-align: center;
    color: #FFFFFF;
  } 

  .footercontact{
    display: block;
  }

}