.main {
  padding-top: 20px;
} 

ul a {
  text-decoration: none;
  
}

.italic {
  font-style: italic;
}

/* Header Classes */
.headerHeight {
  min-height: 64px;
}

/* Sidebar Styles */
#wrapper {
  overflow-x: hidden;
  min-width: 100%;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

.page-content-wrapper {
  min-width: 75%;
  margin-top: 25px;  
  margin-left: 0px;
  padding-left: 15px;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}

@media (max-width: 768px) {
  .headerHeight {
    min-height: 56px;
  }
}