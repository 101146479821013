.iconposition {
  padding-bottom: 6px;
}

.iconContextheader {
  height: 2em;
  width: 2em;
  color: white;
}

.ms-auto {
  margin-left: auto !important;
}

/* Adapt the website for responsive devices */
@media screen and (max-width: 768px) {
  .iconContextheader {
    height: 1.3em;
    width: 1.3em;
    color: white;
    flex-direction: row!important;
  }
}
