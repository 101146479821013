/* General Classes */
body {
  font-family: "Raleway";
  padding-bottom: 40px;
}

.profile {
  height: 15vh;
}

.App {
  text-align: center;
}

.React-logo {
  height: 40px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .React-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Style Resume */

/* Containers borders */

div.dashedborders {
  border-left: double 2px rgba(0, 80, 158, 0.5);
  border-top: dashed 2px rgba(0, 80, 158, 0.5);
  padding-top: 0px;
  padding-left: 25px;

}

.dashedborders1:before {
  content: "\25EF"; 
  color: rgba(0, 80, 158, 0.8);
  font-size: 23px;
  position: relative;
  top: -20px;
  left: -40px;
  background-color: #FFF;
  display: none;
}

.iconCircle {
  min-height: 25px;
  max-width: 25px;
  /* float: left; */
  position:relative;
  background-repeat: no-repeat;
  top:-13px;
  left:-37px;  
  margin-right: 6px;
  color: #61dafb;
  z-index: 50;
  background-color: #fff;
}

.iconCircleDraw {
  background: url(../public/img/iconTop.jpg);
  min-height: 25px;
  /* float: left; */
  position:relative;
  background-repeat: no-repeat;
  top:-10px;
  left:-12px;  
  margin-right: 6px;
  color: #61dafb;
  z-index: 50;
}

button {
  margin-bottom: 2px;
}