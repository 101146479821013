div.sidebar a {
  text-decoration: none;
  color: #4885ed;
}

nav.sidebar {
  align-items: start;
}


/* padding: 0.5rem 1rem; */